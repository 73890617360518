import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { BehaviorSubject, Subject } from 'rxjs';

declare var window: any;
@Injectable()
export class LocalStorageService {
	isRecentLink = new BehaviorSubject<boolean>(false);
	valForm: FormGroup;
	pausePlanIds = FlocksyUtil.pausePlanIds;
	currentUser$: Subject<any> = new Subject<any>();

	getItem(key: string, defaultValue?: any): any {
		if (localStorage.getItem(key)) {
			let data: any;
			if (typeof localStorage.getItem(key) !== 'undefined') {
				try {
					data = JSON.parse(localStorage.getItem(key));
					return data;
				} catch (e) {
					data = '';
					return data;
				}
			} else {
				return '';
			}
		} else {
			return defaultValue;
		}
	}

	listStringFromDbService = ['one', 'two', 'three'];

	setItem(key: string, value: any): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	setUserId(user_id: string): void {
		this.setItem('user_id', user_id);
	}

	getUserId(): string {
		var user_id = this.getItem('user_id', '');
		if (user_id == '') {
			let oUser = this.getCurrentUser();
			if (oUser) {
				return oUser['id'];
			}
		}
		return user_id;
	}

	getParentId(): string {
		var parent_id = this.getItem('parent_id', '');
		if (parent_id == '') {
			let oUser = this.getCurrentUser();
			if (oUser) {
				return oUser['parent_id'];
			}
		}
		return parent_id;
	}

	getParentName(): string {
		var parent_name = this.getItem('parent_name', '');
		if (parent_name == '') {
			let oUser = this.getCurrentUser();
			if (oUser) {
				return oUser['parent_name'];
			}
		}
		return parent_name;
	}

	getOperationalUserId() {
		if (this.isClientUser()) {
			return this.getUserId();
		} else if (this.isSubClientUser()) {
			return this.getParentId();
		} else {
			return this.getUserId();
		}
	}

	setAccessToken(access_token: string): void {
		this.setItem('access_token', access_token);
	}

	getAccessToken(): string {
		return this.getItem('access_token', '');
	}

	setSessionToken(token: string): void {
		this.setItem('session_token', token);
	}

	getSessionToken(): string {
		return this.getItem('session_token', '');
	}

	setUserLoggedIn(logged_in: boolean): void {
		if (typeof logged_in === 'undefined') {
			logged_in = false;
		}
		this.setItem('is_logged_in', logged_in);
	}

	isUserLoggedIn(): boolean {
		return this.getItem('is_logged_in', false);
	}

	setCurrentUser(current_user: object): void {
		this.setItem('current_user', current_user);
		this.currentUser$.next(current_user);
	}

	getCurrentUser(): any {
		return this.getItem('current_user', false);
	}

	getCurrentUser$() {
		return this.currentUser$.asObservable();
	}

	getUserAuthSeamLess() {
		return this.getItem('auth_seamless', false);
	}

	setUserFullName(name) {
		let oUser = this.getCurrentUser();
		if (oUser) {
			oUser['name'] = name;
		}
		this.setItem('current_user', oUser);
	}

	hasMarketplaceAccess() {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['has_marketplace_access'];
		}
		return false;
	}

	hasFirstPaidAt() {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['first_paid_at'];
		}
		return false;
	}

	hasProjectExists() {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['projects_exists'];
		}
		return false;
	}

	getUserFullName(): string {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['name'];
		}
		return '';
	}

	getUserFullPath(): string {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['profile_file_url'];
		}
		return '';
	}

	setMarketPlaceAccess(hasAccess) {
		let oUser = this.getCurrentUser();
		if (oUser) {
			oUser['has_marketplace_access'] = hasAccess;
		}
		this.setItem('current_user', oUser);
	}

	setFirstPaidAt(hasAccess) {
		let oUser = this.getCurrentUser();
		if (oUser) {
			oUser['first_paid_at'] = hasAccess;
		}
		this.setItem('current_user', oUser);
	}

	setProjectExists(hasAccess) {
		let oUser = this.getCurrentUser();
		if (oUser) {
			oUser['projects_exists'] = hasAccess;
		}
		this.setItem('current_user', oUser);
	}

	getUserEmail(): string {
		let oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['email'];
		}
		return '';
	}

	resetSessionStorage(): void {
		this.removeItem('access_token');
		this.removeItem('current_user');
		this.removeItem('is_logged_in');
		this.removeItem('prevPageId');
		this.removeItem('auth_seamless');
		this.removeItem('subClientBanner');
		this.removeItem('currentPlanPeriodType');
		// this.removeItem('is_white_labeled');
		// this.removeItem('white_labeled_user');
	}

	setIntendedUrlParams(params) {
		this.setItem('intended_params', params);
	}

	getIntendedUrlParams() {
		return this.getItem('intended_params');
	}

	getUserType(): string {
		if (this.getCurrentUser()) {
			if (this.getCurrentUser()['is_admin']) {
				return 'Admin';
			} else if (this.getCurrentUser()['is_client']) {
				return 'Client';
			} else if (this.getCurrentUser()['is_sub_client']) {
				return 'SubClient';
			} else if (this.getCurrentUser()['is_designer']) {
				return 'Designer';
			} else if (this.getCurrentUser()['is_project_manager']) {
				return 'ProjectManager';
			}
		}
		return '';
	}

	getUserTypeID(): number {
		if (this.getCurrentUser()) {
			if (this.getCurrentUser()['is_admin']) {
				return 4;
			} else if (this.getCurrentUser()['is_client']) {
				return 1;
			} else if (this.getCurrentUser()['is_sub_client']) {
				return 5;
			} else if (this.getCurrentUser()['is_designer']) {
				return 2;
			} else if (this.getCurrentUser()['is_project_manager']) {
				return 3;
			}
		}
		return null;
	}

	getUserTypeFromId(id) {
		id = Number(id);
		let user = '';
		switch (id) {
			case 1:
				user = 'Client';
				break;

			case 2:
				user = 'Designer';
				break;

			default:
				break;
		}
		return user;
	}

	isClientUser(): boolean {
		return this.getCurrentUser()['is_client'];
	}

	isSubClientUser(): boolean {
		return this.getCurrentUser()['is_sub_client'];
	}

	isDesignerUser(): boolean {
		return this.getCurrentUser()['is_designer'];
	}

	isProjectManagerUser(): boolean {
		return this.getCurrentUser()['is_project_manager'];
	}

	isAdminUser(): boolean {
		return this.getCurrentUser()['is_admin'];
	}

	isOperationalClientUser(): boolean {
		return (
			this.getCurrentUser()['is_client'] ||
			this.getCurrentUser()['is_sub_client']
		);
	}

	getClientPlanID(): any {
		if (this.getCurrentUser()) {
			if (typeof this.getCurrentUser()['plan_id'] != 'undefined') {
				return this.getCurrentUser()['plan_id'];
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	getParentClientPlanID(): any {
		if (this.getCurrentUser()) {
			if (typeof this.getCurrentUser()['parent_plan_id'] != 'undefined') {
				return this.getCurrentUser()['parent_plan_id'];
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	isUpdatedBilling(): boolean {
		if (this.getCurrentUser()) {
			if (
				typeof this.getCurrentUser()['is_updated_billing'] !=
				'undefined'
			) {
				return this.getCurrentUser()['is_updated_billing'] == 1;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	getCreativeLevelId() {
		const oUser = this.getCurrentUser();
		if (oUser) {
			return oUser['creative_level_id'];
		}
		return '';
	}

	isPlatinumLevel(): boolean {
		if (this.getCreativeLevelId() == 4 || this.getCreativeLevelId() == 5) {
			return true;
		}
		return false;
	}

	isPlatinumLead(): boolean {
		if (this.getCreativeLevelId() == 5) {
			return true;
		}
		return false;
	}

	setProfileImageURLFromHash(name) {
		let oUser = this.getCurrentUser();
		name =
			environment.apiEndPoint +
			'/storage/uploads/' +
			name +
			'?v=' +
			btoa(name);
		oUser['profile_file_url'] = name;
		this.setCurrentUser(oUser);
	}

	setProfileImageURL(name) {
		let oUser = this.getCurrentUser();
		oUser['profile_file_url'] = name;
		this.setCurrentUser(oUser);
	}

	getProfileImageURL() {
		if (this.getCurrentUser()) {
			if (this.getCurrentUser()['profile_file_url']) {
				if (
					typeof this.getCurrentUser()['profile_file_url'] !=
					'undefined'
				) {
					return this.getCurrentUser()['profile_file_url'];
				}
			}
		}
		return 'avatar.png';
	}

	getImageS3URL(id, file_hash) {
		return (
			environment.s3BucketURL +
			'/' +
			this.getS3BucketFolder()[id] +
			'/' +
			file_hash
		);
	}

	getS3BucketFolder() {
		return {
			1: 'profile_pic',
			2: 'project_assets',
			3: 'bucket_assets',
			4: 'email_assets',
		};
	}

	getUserTypeIdsFromName(): any {
		return {
			Client: 1,
			Designer: 2,
			ProjectManager: 3,
			Admin: 4,
			SubClient: 5,
		};
	}

	getUpdateInviteMessage(): any {
		return {
			basicSubject:
				'Guess What! You’ve Been Invited to a New Project By a Project Manager',
			emailSubject: 'New Project Invite From a Project Manager',
			basicMessage: `I realize you're new in the platform and wanted to help you grow your profile. Before I gave this to a higher ranking team member, I wanted to give you a chance to review it and place an offer. I think this would be a great client's team to join and a good project to work on. You can also let me know if you have questions without placing an offer. If you're interested, please place an offer. No worries if not.
                    \n Please follow the links and do not respond to this email.'`,
			premiumMessage: `Would you like to work on this project? If you’re not on the clients team yet, it’s a great chance to join it to receive more project invites in the future. If you’re not interested, would you also shoot me a quick note via the question to let me know why you’re not? This helps me know if I should continue to invite you to certain projects.
                    \n Please follow the links and do not respond to this email. `,
			standardMessage: `I see you\'ve been working on a few projects and am excited to have you on the team. I’d like to see you work on more projects and build your profile. I think this client and project would be a great opportunity for you to do so. Please check it out and place an offer if you think so. Also feel free to ask me questions.
                    \n Please follow the links and do not respond to this email.`,
			preferenceSelectedMessage: `There’s a brand new project available and I’m wondering if you’d like to work on it? If you would, just place an offer or alternatively let me know if you have any questions.
                    \n I’m specifically emailing you this invite because you requested to see these types of projects in your Project Preferences.
                    \n Please follow the links and do not respond to this email. `,
			everyoneMessage: `Guess what! There’s a brand new project available and I’m wondering if you’d like to work on it. If you would, just place an offer or alternatively let me know if you have any questions. No worries if not.
                        \n Please follow the links and do not respond to this email. `,
		};
	}

	getProjectQuestionType(): any {
		return {
			text: '1',
			radio: '2',
			enum: '3',
			multienum: '4',
			checkbox: '5',
			textarea: '6',
			file: '7',
			bucket: '8',
		};
	}

	getProjectTypes() {
		return {
			pending: 1,
			active: 2,
			completed: 3,
		};
	}

	setCouponAppliedDate(oDate) {
		this.setItem('coupon_applied_date', oDate);
	}

	getCouponAppliedDate(): Date {
		return this.getItem('coupon_applied_date');
	}

	setCouponCode(coupon_code) {
		this.setItem('coupon_code', coupon_code);
	}

	getCouponCode() {
		return this.getItem('coupon_code');
	}

	setReferralCode(referral_code) {
		this.setItem('referral_code', referral_code);
	}

	getReferralCode() {
		return this.getItem('referral_code');
	}

	setUtmSource(utm_source) {
		this.setItem('utm_source', utm_source);
	}

	getUtmSource() {
		return this.getItem('referral_code');
	}

	setReferralAppliedDate(oDate) {
		this.setItem('referral_applied_date', oDate);
	}

	getReferralAppliedDate(): Date {
		return this.getItem('referral_applied_date');
	}

	setDemoPlan(plan: boolean) {
		this.setItem('is_demo_plan', plan);
	}

	isDemoPlan() {
		return this.getItem('is_demo_plan');
	}

	getDateDiffHours(oDate2: Date, oDate1: Date) {
		return Math.abs(oDate2.getTime() - oDate1.getTime()) / 36e5;
	}

	formatDate(value: any, format: string = ''): string {
		var oMomentUtc = moment.utc(value).toDate();
		return moment(oMomentUtc, 'YYYY-MM-DD hh:mm:ss').local().format(format);
	}

	newformatDate(value: any, format: string = ''): string {
		const currentDate = moment();
		const inputDate = moment(value);

		if (currentDate.diff(inputDate, 'days') === 0) {
			return inputDate.fromNow();
		} else if (currentDate.diff(inputDate, 'days') === 1) {
			return 'Yesterday';
		} else if (currentDate.diff(inputDate, 'days') <= 30) {
			return inputDate.fromNow();
		} else {
			return inputDate.format('MMM DD, YYYY');
		}
	}

	formatDateWithAmPM(value: any): string {
		var oMomentUtc = moment.utc(value).toDate();
		return moment(oMomentUtc).local().format('MMMM D, YYYY [at] hh:mmA');
	}

	getProjectActiveStatusName(id) {
		switch (id) {
			case 1:
				return 'Update Pending';
			case 2:
				return 'Update Sent';
			default:
				return '';
		}
	}

	getCreativeLevelName(id) {
		switch (id) {
			case 1:
				return 'Basic';
			case 2:
				return 'Standard';
			case 3:
				return 'Premium';
			case 4:
				return 'Platinum';
			default:
				return '';
		}
	}

	getProjectDeclineReasons() {
		return [
			{
				id: 1,
				title: "This project doesn't meet my qualifications.",
				isChecked: false,
			},
			{
				id: 2,
				title: "I'd prefer not to work with this client.",
				isChecked: false,
			},
			{
				id: 3,
				title: "The project brief isn't clear as to what the client wants.",
				isChecked: false,
			},
			{
				id: 4,
				title: 'There are too many special rules and stipulations.',
				isChecked: false,
			},
			{
				id: 5,
				title: 'Resource materials are inadequate for the request.',
				isChecked: false,
			},
			{
				id: 6,
				title: "The client is requesting something Flocksy doesn't offer.",
				isChecked: false,
			},
			{
				id: 7,
				title: 'The client requested someone specific to work on this project.',
				isChecked: false,
			},
			{
				id: 8,
				title: 'The client requested additional items outside of the project brief.',
				isChecked: false,
			},
			{
				id: 9,
				title: "I don't have time to complete this project",
				isChecked: false,
			},
			{
				id: 10,
				title: 'Other',
				isChecked: false,
			},
		];
	}

	getTriggerStatuses() {
		return {
			1: 'Manually',
			2: 'Manually through Bid',
			3: 'Custom Auto Assign',
			4: 'Accept Now',
			5: 'Platinum Auto Assign',
			6: 'Timer Entries',
		};
	}

	getEditorConfig() {
		return {
			placeholder: 'Your Comment',
			toolbar: [
				['font', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol']],
			],
			popover: {
				// this is needed to hide the popover from appearing automatically
				image: [],
				link: [],
				air: [],
			},
			options: {
				modules: {
					autoLink: false,
				},
			},
			styleTags: [], //will be used to provide unique id to all the instance for summernote emoji editor
			callbacks: {
				onPaste: function (e) {
					var bufferText = (
						(e.originalEvent || e).clipboardData ||
						window.clipboardData
					).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onKeyup: function (e) {
					e.preventDefault();
				},
			},
			disableDragAndDrop: true,
		};
	}

	formatNumber(value: any) {
		return value.toLocaleString('en-US');
	}

	isProjectManagerAsAM() {
		if (this.getCurrentUser()['detail']) {
			return (
				this.getCurrentUser()['user_type_id'] == 3 &&
				this.getCurrentUser()['detail']['pm_role_id'] == 1
			);
		} else {
			return false;
		}
	}

	isUserOnPausePlan() {
		let plan_id = this.getClientPlanID();
		return !!this.pausePlanIds.find((val) => val === plan_id);
	}

	saveRoute(route: any): void {
		let history = JSON.parse(localStorage.getItem('routerHistory')) || [];
		history.unshift(route); // Add the new route at the beginning of the array
		history = history.filter((item, index) => {
			let historyIndex = history.findIndex((res) => {
				return res?.link == item?.link;
			});
			return historyIndex == index;
		});
		if (history.length > 5) {
			history.pop(); // Remove the oldest route if maximum history length is reached
		}
		this.setItem('routerHistory', history);
	}

	formatRelativeOrAbsoluteDate(date: any): string {
		if (moment().diff(moment(date), 'days') < 7) {
			return moment(date).fromNow() == 'a day ago'
				? 'Yesterday'
				: moment(date).fromNow();
		} else {
			return moment(date).format('MMMM D, YYYY h:mm A');
		}
	}


}
